import "../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VUy3LbMAy8+yt46Ux9oEd2bNdhbv2RDi1CEhKKVEnIstPJv3dEWrFezuMKEIvFYonVn/XfRyifKWH/FoxlGs48d7YRbP20YEyhr7S8iJBoA8+1J8wuPLWGwFBMcDCqTZZoeIOKCsHWu6QKBaU8d7FNjL0tVl3TdWh6lOlL7mxtlGBNgQRt3bAm4hSAeUGCPSSnoo3ZE7hM24ZfBJM12QH2JmATnImTk8Zn1pWCpbJCkhpfQ5PMGuLNFXWfJNPGPcCHADhRJAig0EFKaI1gqdV1aSJll6PhR0tkS8E22xHgNgCOXh3iuIGZx1cQ7CTdT85llqEBHuPlcsJ+F9mnVls3qgkaeEitUdJdlgMOu8ChW1qS/GhBOp3Xh6v4R+sUOMHW1Zl5q1ENG2h5AcfReFTwOzzlsWJ5K+ZOKqy9YNsI+bmzUjAErk1JjbnhSFD6W7g3xD7ayJ65L6RqzWusCQu+WYvPKROJ9x4ROMKJSL/mNz9Li7FKKoUmf590JO78D/IkHbXpXFaC7UdOOcx+zm4r3bC2Jo0GboEvmyiTJeqLYGgKcEg9I/UiUy3Dv6qkA0NDukJUWqZQWK3ABe53fdl7OBT98W4dptbwymEZFzWYczP+tXJ+d5Mt+EqmwI9ADYD5VLyzX37ggO4uCVagUhFu4IIev+N3r8qg59A7s33bg8rDdK0xGierp+4u3t6D1lh59ANq6ceb61+Uicu/23aOfJ/K6r3b10n16tXdqmNNNLLT9SJryOh6j9/+Ax/m+qknBwAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var InputContainer = '_1q9emjt7';
export var fontItem = '_1q9emjtb';
export var fontItemContainer = '_1q9emjta';
export var fontLabel = '_1q9emjtc';
export var menu = '_1q9emjt1';
export var menuTrigger = '_1q9emjt2';
export var searchIcon = '_1q9emjt9';
export var searchInput = '_1q9emjt8';
export var selectedIcon = '_1q9emjtd';
export var settingWrapper = '_1q9emjt0';
export var shapeIndicator = '_1q9emjt6';
export var snapshot = '_1q9emjt5';
export var snapshotContainer = '_1q9emjt3';
export var snapshotTitle = '_1q9emjt4';